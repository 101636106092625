import { openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  "fill-rule": "evenodd",
  "clip-rule": "evenodd",
  d: "M6.34315 6.34315C7.84344 4.84285 9.87827 4 12 4C14.1217 4 16.1566 4.84285 17.6569 6.34315C19.1571 7.84344 20 9.87827 20 12C20 13.0506 19.7931 14.0909 19.391 15.0615C18.989 16.0321 18.3997 16.914 17.6569 17.6569C16.914 18.3997 16.0321 18.989 15.0615 19.391C14.0909 19.7931 13.0506 20 12 20C10.9494 20 9.90914 19.7931 8.93853 19.391C7.96793 18.989 7.08601 18.3997 6.34315 17.6569C5.60028 16.914 5.011 16.0321 4.60896 15.0615C4.20693 14.0909 4 13.0506 4 12C4 9.87827 4.84285 7.84344 6.34315 6.34315ZM12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12C2 13.3132 2.25866 14.6136 2.7612 15.8268C3.26375 17.0401 4.00035 18.1425 4.92893 19.0711C5.85752 19.9997 6.95991 20.7362 8.17317 21.2388C9.38642 21.7413 10.6868 22 12 22C13.3132 22 14.6136 21.7413 15.8268 21.2388C17.0401 20.7362 18.1425 19.9997 19.0711 19.0711C19.9997 18.1425 20.7362 17.0401 21.2388 15.8268C21.7413 14.6136 22 13.3132 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2ZM15.8234 8.16624C15.0713 7.4261 14.0572 7.01313 13.002 7.01731C11.9467 7.02148 10.9359 7.44245 10.1897 8.18852C9.44343 8.93458 9.02223 9.9453 9.01781 11.0005C9.0148 11.718 9.20465 12.4165 9.5591 13.0267L7.29289 15.2929C6.90237 15.6834 6.90237 16.3166 7.29289 16.7071C7.68342 17.0976 8.31658 17.0976 8.70711 16.7071L10.9643 14.4499C11.1244 14.5457 11.2914 14.6304 11.4641 14.7032C11.9506 14.9083 12.473 15.015 13.001 15.0172C13.529 15.0195 14.0523 14.9171 14.5405 14.7161C15.0288 14.5151 15.4724 14.2194 15.8459 13.846C16.2193 13.4727 16.5151 13.0291 16.7162 12.5409C16.9174 12.0527 17.0198 11.5295 17.0177 11.0015C17.0157 10.4734 16.909 9.95105 16.7041 9.46444C16.4991 8.97782 16.1998 8.53661 15.8234 8.16624ZM13.0226 13.3925C14.3359 13.3925 15.4006 12.3278 15.4006 11.0145C15.4006 9.7011 14.3359 8.63641 13.0226 8.63641C11.7092 8.63641 10.6445 9.7011 10.6445 11.0145C10.6445 12.3278 11.7092 13.3925 13.0226 13.3925Z",
  fill: "currentColor"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("path", _hoisted_1))
}